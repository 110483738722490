import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

const Casio = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="Shoya, Inc. to Acquire Casio Computer Co. Ltd." />
        <meta name="twitter:image" content="http://www.shoya.co/static/shoyaright_icon-ada66d9717351d5e161cdd4c5d83603b.png" />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <div className="article">
        <Link to="/shoyaright">
        <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p> Shoya, Inc. to Acquire Casio Computer Co., Ltd. in Landmark Deal </p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. Jan. 30, 2020) </p>
        </div>
        <div className="articleBody">
        <p>
        It's reported that on the morning of January 29th, 2020, top leadership from both companies met in a poorly decorated boardroom with too few snacks, to discuss the potential of Shoya, Inc. buying out Casio using a mixture of cash and other assets.
        </p>
        <p>
        An anonymous source close to the CEO of Shoya, Inc. infers that talks were initiated due to the leader's deep personal appreciation of Casio's watchmaking, citing particular enthusiasm for the F-91W.
        </p>
        <p>
        "He won't stop talking about that damn watch" claims the source. "I was like 'Bro, it's not an AP. It's not a Richard Mille, I don't get why you like it so much' and all he kept telling me was that the battery lasts for 7 years and that it has a millisecond stopwatch and that it glows in the dark. Granted, the battery in my Audemars only lasts 30 months, so maybe he's onto something..."
        </p>
        <p>
        It appears that Shoya, Inc. intends to implement a billion-dollar marketing strategy centering the Casio F-91W, with the goal of making the watch as ubiquitous as the iPhone.
        </p>
        <p>
        It's also reported that Yamaha, upon hearing rumors of the acquisition, is shaking.
        </p>

        </div>
        <p className="plug">If you enjoyed this article, consider <a href="https://www.patreon.com/join/shoyaright?">becoming a patron</a> or making a <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">one-time pledge</a>. Support corporate journalism!</p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2020</p>
        </div>
      </div>
    </div>

  );
};
export default Casio;
